import { useEffect } from "react"

export const useDetectOutsideClick = (ref, funcToExecute) => {
  useEffect(() => {
    // call funcToExecute if the user clicked outside of the ref
    function handleClickOutside(event) {
      // if we click outside of the ref
      // AND if we didn't click on something with the class "hamburger-icon"
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        typeof event.srcElement.className === "string" &&
        !event.srcElement.className.includes("hamburger-icon")
      ) {
        funcToExecute()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      // Clean up event listener
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, funcToExecute])
}
