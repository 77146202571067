import { base, deep } from "@theme-ui/presets"
import "fontsource-proza-libre"
import "fontsource-open-sans"
import nightOwlLight from "@theme-ui/prism/presets/night-owl-light.json"
import nightOwl from "@theme-ui/prism/presets/night-owl.json"

const prismTheme = nightOwlLight

export default {
  ...base,
  breakpoints: ["300px", "500px", "750px", "1000px"],
  // useColorSchemeMediaQuery: true,
  colors: {
    ...base.colors,
    background: prismTheme.backgroundColor,
    text: "#1B1A2B",
    primary: prismTheme[".operator,.property,.keyword,.namespace"].color,
    // secondary: prismTheme[".inserted,.attr-name"].color,
    // secondary: `#519072`,
    secondary: `#498367`,
    highlight: `#f0f0f0`,
    accent: prismTheme[".punctuation"].color,
    muted: prismTheme[".comment"].color,
    // lightened: "#99BCFF",
    lightened: "rgb(135, 187, 162)",

    modes: {
      dark: {
        ...deep.colors,
        // lightened: deep.colors.highlight,
        lightened: "#322154",
        primary: nightOwl[".builtin,.char,.constant,.function"].color,
        secondary: nightOwl[".tag,.operator,.keyword"].color,
        highlight: `#202f3c`,
        background: nightOwl.backgroundColor,
        accent: nightOwl[".punctuation"].color,
      },
    },
  },
  fonts: {
    ...base.fonts,
    body: "Open Sans",
    heading: "Proza Libre",
  },
  styles: {
    ...base.styles,
    img: {
      // center images in mdx files
      display: "block",
      margin: "0 auto",
    },
    p: {
      fontSize: ["1rem", "1rem", "1.1rem", "1.1rem"],
      lineHeight: "1.75",
    },
    h2: {
      fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"],
    },
    h3: {
      fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"],
    },
    li: {
      fontSize: ["1rem", "1rem", "1.1rem", "1.1rem"],
      paddingInlineStart: "1ch",
    },
    ul: {
      lineHeight: "1.75",

      li: {
        "&::marker": {
          content: "'»'",
          fontSize: "1.5rem",
          color: theme => theme.colors.primary,
        },
      },
    },

    a: {
      fontSize: ["1rem", "1rem", "1.1rem", "1.1rem"],
    },
    blockquote: {
      bg: theme => theme.colors.highlight,
      borderLeft: theme => `6px solid ${theme.colors.accent}`,
      padding: "2px 4px",

      p: {
        margin: "0.5em",
      },
    },

    CodeBlock: {
      ...prismTheme,
      borderStyle: `solid`,
      borderWidth: `4px`,
      borderColor: theme => theme.colors.highlight,
      overflow: `auto`,
      backgroundColor: `none`,

      pre: {
        backgroundColor: `rgba(255,255,255,0)`,
        float: `left`,
        minWidth: `100%`,
        margin: 0,
        padding: `10px`,
        height: `auto`,
        maxHeight: `500px`,
      },
      highlightLine: {
        backgroundColor: theme => theme.colors.highlight,
        borderLeftColor: theme => theme.colors.secondary,
        borderLeftStyle: `solid`,
        borderLeftWidth: `4px`,
        display: `block`,
        margin: `0 -10px`, // margin helps the highlight extend to the whole parent div (which has a padding of 10px)
        padding: `0 10px`, // padding helps "bring back" the 10px padding of the parent div
      },
      title: {
        fontFamily: `Menlo,monospace`,
        bg: theme => theme.colors.highlight,
        color: theme => theme.colors.text,
        padding: `4px 10px`,
      },
      lineNumber: {
        ariaHidden: `true`,
        display: `inline-block`,
        width: `1.2em`,
        userSelect: `none`,
        opacity: `0.3`,
        textAlign: `right`,
        position: `relative`,
        left: `calc(-1.45rem + 12px)`,
        marginRight: `4px`
      },
    },

    InlineCode: {
      fontSize: ["1.1rem", "1.1rem", "1.2rem", "1.2rem"],
      boxSizing: "border-box",
      padding: "4px",
      borderRadius: "3px",
      color: theme => theme.colors.accent,
    },

    Emphasize: {
      fontWeight: "700",
      color: theme => theme.colors.secondary,
    },

    // https://codepen.io/jgarrow/pen/bGejwGO?editors=1100
    animateUnderline: {
      cursor: "pointer",
      color: "text",
      textDecoration: "none",
      backgroundImage: theme =>
        `linear-gradient(${theme.colors.text}, ${theme.colors.text}), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 2px, 0 2px",
      backgroundPosition: "100% 100%, 0 100%",
      // transition: "background-size .15s linear",
      transition: "all .15s linear",

      span: {
        color: "text",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "rgba(255,255,255,0)",
        backgroundImage: theme => `linear-gradient(
                              to right,
                              ${theme.colors.secondary},
                              ${theme.colors.secondary} 50%,
                              ${theme.colors.text} 50%)`,
        backgroundSize: "200% 100%",
        backgroundPosition: "100%",
        transition: "all 0.2s cubic-bezier(0.000, 0.000, 0.230, 1)",
      },

      ":hover": {
        backgroundSize: "0 2px, 100% 2px",
        color: "secondary",
      },

      ":hover span": {
        backgroundPosition: "0%",
      },
    },
    animateTextFill: {
      color: "text",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "rgba(255,255,255,0)",
      backgroundImage: theme => `linear-gradient(
                              to right,
                              ${theme.colors.secondary},
                              ${theme.colors.secondary} 50%,
                              ${theme.colors.text} 50%)`,
      backgroundSize: "200% 100%",
      backgroundPosition: "100%",
      transition: "all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1)",

      ":hover": {
        backgroundPosition: "0%",
      },
    },
  },
}
