/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import AnimatedLink from "./animated-link"

import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick"

const NavLinkStyles = {
  backgroundImage: theme =>
    `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.text}, ${theme.colors.text})`,
  fontSize: ["1rem", "1rem", "1.2rem"],

  "&:hover": {
    color: "text",
  },

  span: {
    variant: `styles.animateUnderline.span`,
    backgroundImage: theme =>
      `linear-gradient(${theme.colors.text}, ${theme.colors.text}), linear-gradient(${theme.colors.text}, ${theme.colors.text})`,
  },
}

const Nav = ({ isMenuOpen, closeNav }) => {
  const navRef = React.createRef()
  useDetectOutsideClick(navRef, closeNav)

  return (
    <div
      ref={navRef}
      sx={{
        width: [`200px`, `200px`, `100%`, `100%`],
        height: [`100vh`, `100vh`, `auto`, `auto`],
        display: `flex`,
        flexDirection: [`column`, `column`, `row`, `row`],
        alignItems: [`flex-start`, `flex-start`, `center`, `center`],
        justifyContent: [
          `flex-start`,
          `flex-start`,
          `space-between`,
          `space-between`,
        ],
        position: [`absolute`, `absolute`, `relative`, `relative`],
        right: [
          isMenuOpen ? `-20%` : `-100vw`,
          isMenuOpen ? `-20%` : `-100vw`,
          `0`,
          `0`,
        ],
        top: [`-1.45rem`, `-1.45rem`, `0`, `0`],
        transition: "all .3s ease-out",
        bg: [
          `lightened`,
          `lightened`,
          `rgba(255,255,255,0)`,
          `rgba(255,255,255,0)`,
        ],
        boxSizing: `border-box`,
        padding: [`90px 16px 0`, `90px 16px 0`, `0`, `0`],
      }}
    >
      <nav
        sx={{
          width: [`120px`, `120px`, `250px`, `250px`],
          height: [`100px`, `100px`, `46px`, `46px`],
          display: `flex`,
          flexDirection: [`column`, `column`, `row`, `row`],
          justifyContent: `space-between`,
          alignItems: [`flex-start`, `flex-start`, `center`, `center`],
        }}
      >
        <AnimatedLink isInternal={true} href="/" styles={NavLinkStyles} onClick={closeNav}>
          Home
        </AnimatedLink>
        <AnimatedLink
          isInternal={true}
          href="/portfolio/"
          styles={NavLinkStyles}
          onClick={closeNav}
        >
          Portfolio
        </AnimatedLink>
        <AnimatedLink isInternal={true} href="/blog/" styles={NavLinkStyles} onClick={closeNav}>
          Blog
        </AnimatedLink>
      </nav>
    </div>
  )
}

export default Nav
