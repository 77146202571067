// used as reference for Framer Motion page transitions
// https://github.com/ryanwiemer/gatsby-using-page-transitions
import React from "react"
import Layout from "./src/components/layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

// reference: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-remark-autolink-headers/src/gatsby-browser.js
const getTargetOffset = hash => {
  let offsetY = 110
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      let computedStyles = window.getComputedStyle(element)
      let scrollMarginTop =
        computedStyles.getPropertyValue(`scroll-margin-top`) ||
        computedStyles.getPropertyValue(`scroll-snap-margin-top`) ||
        `0px`

      return (
        element.getBoundingClientRect().top +
        scrollTop -
        parseInt(scrollMarginTop, 10) -
        clientTop -
        offsetY
      )
    }
  }
  return null
}

// to prevent page from scrolling to the top during page transitions
// https://www.gatsbyjs.com/docs/browser-apis/#shouldUpdateScroll

// bug w/ shouldUpdateScroll fixed in Gatsby v2.28.1
// this solution works fine in Odyssey, but still seems off here
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  
  if (location.hash) {
    const offset = getTargetOffset(location.hash)
    if (offset !== null) {
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      })
    }
    return false
  }

  const TRANSITION_DELAY = 0.25 * 1000 * 2

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
