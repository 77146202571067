/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"

const AnimatedLink = ({
  isInternal = false,
  href,
  children,
  styles,
  ...props
}) => {
  return (
    <React.Fragment>
      {isInternal ? (
        <Link
          {...props}
          to={href}
          sx={{ variant: `styles.animateUnderline`, ...styles }}
        >
          {children}
          {/* <span>{children}</span> */}
        </Link>
      ) : (
        <a
          {...props}
          href={href}
          sx={{ variant: `styles.animateUnderline`, ...styles }}
        >
          {children}
          {/* <span>{children}</span> */}
        </a>
      )}
    </React.Fragment>
  )
}

export default AnimatedLink
