/** @jsx jsx */
import { jsx } from "theme-ui"

const HamburgerIcon = ({ isMenuOpen, setIsMenuOpen }) => {
  const label = isMenuOpen ? `Close menu` : `Open menu`

  return (
    <button
      aria-label={label}
      title={label}
      onClick={setIsMenuOpen}
      className="hamburger-icon"
      sx={{
        background: "none",
        border: "none",
        display: ["block", "block", "none", "none"],
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        width: "46px",
        height: "46px",
        padding: "16px 14px",
        position: "relative",
        zIndex: "20",
      }}
    >
      <span
        className="hamburger-icon"
        sx={{
          bg: isMenuOpen ? "rgba(0,0,0,0)" : "text",
          display: "block",
          height: "2px",
          width: "18px",
          position: "relative",
          transition: "all .1s ease-out",

          "&:before": {
            position: "absolute",
            content: "''",
            bg: "text",
            display: "block",
            width: "100%",
            height: "100%",
            top: isMenuOpen ? "0" : "5px",
            transform: isMenuOpen ? "rotate(-45deg)" : "rotate(0deg)",
            transition: "all .2s ease-out",
          },

          "&:after": {
            position: "absolute",
            content: "''",
            bg: "text",
            display: "block",
            width: "100%",
            height: "100%",
            top: isMenuOpen ? "0" : "-5px",
            transform: isMenuOpen ? "rotate(45deg)" : "rotate(0deg)",
            transition: "all .2s ease-out",
          },
        }}
      ></span>
    </button>
  )
}

export default HamburgerIcon
