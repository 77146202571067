/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Emoji from "./emoji"
import AnimatedLink from "./animated-link"

const BannerChildren = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      portrait: file(relativePath: { eq: "minimalist-portrait.png" }) {
        childImageSharp {
          fluid(maxWidth: 675) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (
    children.props.location.pathname.includes("portfolio") &&
    children.props?.data?.allMdx
  ) {
    return (
      <React.Fragment>
        <h2
          sx={{
            marginBottom: "0.67em",
            fontSize: ["1.5rem", "1.5rem", "1.75rem", "2rem"],
          }}
        >
          Portfolio
        </h2>
        <h3
          sx={{
            fontSize: ["1.1rem", "1.1rem", "1.15rem", "1.25em"],
            fontWeight: "400",
            marginTop: "0.83em",
          }}
        >
          Some of my favorite web development projects
        </h3>
      </React.Fragment>
    )
  }

  //   if it's a blog list page, it will have data.allMdx
  else if (children.props.data && children.props.data.allMdx) {
    return (
      <React.Fragment>
        <h2
          sx={{
            marginBottom: "0.67em",
            fontSize: ["1.5rem", "1.5rem", "1.75rem", "2rem"],
          }}
        >
          Blog
        </h2>
        <h3
          sx={{
            fontSize: ["1.1rem", "1.1rem", "1.15rem", "1.25em"],
            fontWeight: "400",
            marginTop: "0.83em",
          }}
        >
          Various guides and musings about web development
        </h3>
      </React.Fragment>
    )

    // if it's a blog page, it will have data.mdx
  } else if (children.props.data && children.props.data.mdx) {
    return (
      <React.Fragment>
        <h2
          sx={{
            marginBottom: children.props.data.mdx.frontmatter.date
              ? "0.25em"
              : "0.67em",
            fontSize: ["1.5rem", "1.5rem", "1.75rem", "2rem"],
          }}
        >
          {children.props.data.mdx.frontmatter.title}
        </h2>
        {children.props.data.mdx.frontmatter.date ? (
          <small>{children.props.data.mdx.frontmatter.date}</small>
        ) : null}
        <h3
          sx={{
            fontSize: ["1.1rem", "1.1rem", "1.15rem", "1.25em"],
            fontWeight: "400",
            marginTop: children.props.data.mdx.frontmatter.date
              ? "0.4em"
              : "0.83em",
          }}
        >
          {children.props.data.mdx.frontmatter.description}
        </h3>
      </React.Fragment>
    )
  }

  //   otherwise it's a "normal" non-mdx page, like the home page
  return (
    <div
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <div
        sx={{
          width: ["20%", "20%", "30%", "30%"],
          maxWidth: "200px",
          position: "relative",
          display: ["none", "none", "flex", "flex"],
          alignItems: "center",
          justifyContent: "flex-start",
          marginRight: "1rem",
        }}
      >
        <Img
          fluid={data.portrait.childImageSharp.fluid}
          alt="Minimalist portrait of Janessa Garrow"
          sx={{
            width: "100%",
            maxWidth: "200px",
            height: "auto",
          }}
        />
      </div>
      <div
        sx={{
          width: ["100%", "100%", "70%", "70%"],
        }}
      >
        <h2
          sx={{
            marginBottom: "0.67em",
            fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"],
          }}
        >
          Hi, I'm Janessa! <Emoji emojiName="wave">👋</Emoji>
        </h2>
        <p
          sx={{
            fontSize: ["1.1rem", "1.1rem", "1.15rem", "1.25em"],
            fontWeight: "400",
            marginTop: "0.83em",
          }}
        >
          I'm a fullstack engineer with a soft spot for education and UX/UI development. I am currently a senior frontend engineer at{" "}
          <AnimatedLink
            href="https://www.mx.com/"
            styles={{
              backgroundImage: theme =>
                `linear-gradient(${theme.colors.text}, ${theme.colors.text}), linear-gradient(${theme.colors.background}, ${theme.colors.background})`,

              "&:hover": {
                color: "background",
              },

              span: {
                variant: `styles.animateUnderline.span`,
                backgroundImage: theme =>
                  `linear-gradient(
                    to right,
                    ${theme.colors.background},
                    ${theme.colors.background} 50%,
                    ${theme.colors.text} 50%)`,
              },
            }}
          >
            MX Technologies
          </AnimatedLink>
          {/* I am a former DX Engineer at{" "}
          <AnimatedLink
            href="https://www.apollographql.com/"
            styles={{
              backgroundImage: theme =>
                `linear-gradient(${theme.colors.text}, ${theme.colors.text}), linear-gradient(${theme.colors.background}, ${theme.colors.background})`,

              "&:hover": {
                color: "background",
              },

              span: {
                variant: `styles.animateUnderline.span`,
                backgroundImage: theme =>
                  `linear-gradient(
                              to right,
                              ${theme.colors.background},
                              ${theme.colors.background} 50%,
                              ${theme.colors.text} 50%)`,
              },
            }}
          >
            Apollo GraphQL
          </AnimatedLink> */}
          .
        </p>
      </div>
    </div>
  )
}

export default BannerChildren
