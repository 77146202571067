/** @jsx jsx */
import { jsx } from "theme-ui"

import { FaTwitter, FaGithub, FaEnvelope } from "react-icons/fa"

const IconStyles = {
  fontSize: ["1.75rem", "1.75rem", "2rem", "2rem"],
  color: "text",
  transition: "color 0.2s ease-in-out",

  "&:hover": {
    color: "secondary",
  },
}

const Footer = () => {
  return (
    <footer
      sx={{
        padding: `1.45rem 0`,
        textAlign: `center`,
        width: `100%`,
        color: "text",
        bg: "rgba(255,255,255,0)",
        fontSize: "1.1rem",
      }}
    >
      <div
        sx={{
          width: ["90%", "90%", "40%", "40%"],
          maxWidth: "calc(70ch + 350px + 32px)",
          margin: "0 auto",
          display: `flex`,
          alignItems: `center`,
          justifyContent: "space-evenly",
        }}
      >
        <a href="https://github.com/jgarrow">
          <FaGithub sx={IconStyles} />
        </a>
        <a href="https://twitter.com/JanessaGarrow">
          <FaTwitter sx={IconStyles} />
        </a>
        <a href="mailto:janessa.garrow@gmail.com">
          <FaEnvelope sx={IconStyles} />
        </a>
      </div>
    </footer>
  )
}

export default Footer
