/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion, AnimatePresence } from "framer-motion"

const variant = {
  initial: {
    opacity: 0,
    x: -200
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      mass: 0.35,
      stiffness: 75,
      delay: 0.6,
      duration: 0.25
    }
  },
  exit: {
    opacity: 0,
    x: 200
  },
}

const Banner = ({ children, location }) => {
  return (
    <div
      sx={{
        height: "350px",
        gridRow: "1",
        gridColumn: "1 / -1",
        bg: "lightened",
        // bg: "rgb(92, 152, 205)",
        position: "relative",
        zIndex: 4,
        marginBottom: "2rem",
      }}
    >
      {/* sneaky banner */}
      <div
        sx={{
          display: "block",
          position: "sticky",
          top: 0,
          width: "100%",
          height: "95px",
          bg: "lightened",
          zIndex: 5,
        }}
      ></div>

      <AnimatePresence>
        <motion.div
          key={`banner-${location.pathname}`}
          variants={variant}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.25
          }}
          sx={{
            width: ["90%", "90%", "90%", "80%"],
            maxWidth: "calc(70ch + 350px + 32px)",
            height: "224px",
            margin: "0 auto",
            marginTop: "31px",
            color: "text",
            // display: "grid",
            // gridTemplateColumns: "1fr",
            // gridColumnGap: ["0", "32px"],
            // alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default Banner
