// https://github.com/jlengstorf/get-share-image/blob/main/src/index.ts

function cleanText(text) {
    return encodeURIComponent(text).replace(/%(23|2C|2F|3F|5C)/g, '%25$1');
  }

export default function generateSocialImage({
    templateBgColor = 'ffffff',
    title,
    tagline,
    cloudName = 'legendofnessa',
    imagePublicID,
    cloudinaryUrlBase = 'https://res.cloudinary.com',
    thumbOverlayImagePublicId = 'portraitinitials',
    thumbOverlayWidth = 360,
    thumbOverlayHeight = 350,
    thumbOverlayGravity = 'south_west',
    thumbOverlayBottomOffset = 159,
    thumbOverlayLeftOffset = 40,
    titleFont = 'Source%20Sans%20Pro',
    titleExtraConfig = '',
    taglineExtraConfig = '',
    taglineFont = 'Source%20Sans%20Pro',
    imageWidth = 1280,
    imageHeight = 669,
    textAreaWidth = 760,
    textLeftOffset = 480,
    titleGravity = 'south_west',
    taglineGravity = 'north_west',
    titleLeftOffset = null,
    taglineLeftOffset = null,
    titleBottomOffset = 254,
    taglineTopOffset = 445,
    textColor = '000000',
    titleColor,
    taglineColor,
    titleFontSize = 64,
    taglineFontSize = 36,
    version = null,
  }) {
    // configure social media image dimensions, quality, and format
    const imageConfig = [
      `b_rgb:${templateBgColor}`,
      `w_${imageWidth}`,
      `h_${imageHeight}`,
      'c_fill',
      'q_auto',
      'f_auto',
    ].join(',');

    // configure thumb overlay image
    const thumbOverlayConfig = [
        `l_${thumbOverlayImagePublicId}`,
        `w_${thumbOverlayWidth}`,
        `h_${thumbOverlayHeight}`,
        `g_${thumbOverlayGravity}`,
        `y_${thumbOverlayBottomOffset}`,
        `x_${thumbOverlayLeftOffset}`
    ].join(',')
  
    // configure the title text
    const titleConfig = [
      `w_${textAreaWidth}`,
      'c_fit',
      `co_rgb:${titleColor || textColor}`,
      `g_${titleGravity}`,
      `x_${titleLeftOffset || textLeftOffset}`,
      `y_${titleBottomOffset}`,
      `l_text:${titleFont}_${titleFontSize}${titleExtraConfig}:${cleanText(
        title,
      )}`,
    ].join(',');
  
    // configure the tagline text
    const taglineConfig = tagline
      ? [
          `w_${textAreaWidth}`,
          'c_fit',
          `co_rgb:${taglineColor || textColor}`,
          `g_${taglineGravity}`,
          `x_${taglineLeftOffset || textLeftOffset}`,
          `y_${taglineTopOffset}`,
          `l_text:${taglineFont}_${taglineFontSize}${taglineExtraConfig}:${cleanText(
            tagline,
          )}`,
        ].join(',')
      : undefined;
  
    // combine all the pieces required to generate a Cloudinary URL
    const urlParts = [
      cloudinaryUrlBase,
      cloudName,
      'image',
      'upload',
      imageConfig,
      titleConfig,
      taglineConfig,
      version,
      thumbOverlayConfig,
      imagePublicID,
    ];
  
    // remove any falsy sections of the URL (e.g. an undefined version)
    const validParts = urlParts.filter(Boolean);
  
    // join all the parts into a valid URL to the generated image
    return validParts.join('/');
  }