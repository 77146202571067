/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import HamburgerIcon from "./hamburger-icon"
import DarkModeToggle from "./dark-mode-toggle"
import Nav from "./header-nav"
import AnimatedInitials from "./animated-initials"

const Header = ({ location }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  return (
    <header
      sx={{
        bg: location.pathname !== "/" ? `rgba(255,255,255,0)` : `lightened`,
        marginBottom: `1.45rem`,
        width: `100%`,
        position: `fixed`,
        top: `0`,
        zIndex: 6,
      }}
    >
      <div
        sx={{
          gridColumn: "2",
          width: ["90%", "90%", "90%", "80%"],
          maxWidth: "calc(70ch + 350px + 32px)",
          margin: "0 auto",
          padding: `1.45rem 0`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `space-between`,
        }}
      >
        <Link
          to="/"
          sx={{
            color: `text`,
            textDecoration: `none`,
            display: ["none", "flex", "flex", "flex"],
          }}
        >
          <AnimatedInitials
            styles={{
              height: "46px",
              width: "auto",
            }}
          />
        </Link>

        <div
          sx={{
            display: "flex",
            flexDirection: ["row", "row", "row-reverse", "row-reverse"],
            alignItems: "center",
            justifyContent: "space-between",
            position: `relative`,
            width: ["100px", "100px", "320px", "320px"],
          }}
        >
          <HamburgerIcon
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={e => {
              e.stopPropagation()
              setIsMenuOpen(!isMenuOpen)
            }}
          />

          <DarkModeToggle />

          <Nav isMenuOpen={isMenuOpen} closeNav={() => setIsMenuOpen(false)} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
