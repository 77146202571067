/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"

const ScrollGradient = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  // for some reason, iOS doesn't like the `transparent` keyword or rgba(255,255,255,0) and is always white
  // work around for now -- hard code transparency colors
  // tranparent value for dark mode's `background` color (theme ui colors package isn't working, can't get the `alpha` function to work)
  const gradient = isDark ? `rgba(1, 22, 39, 0)` : `rgba(255,255,255,0)`

  return (
    <div
      className="gradient"
      sx={{
        position: "fixed",
        top: "0",
        zIndex: 3,
        width: "100%",
        height: "130px",
        backgroundImage: theme =>
          `linear-gradient(${theme.colors.background}, ${theme.colors.background} 55%, ${gradient})`,
      }}
    ></div>
  )
}

export default ScrollGradient
