/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import generateSocialImage from '../utils/generateSocialImage'
function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const socialCard = React.useMemo(() => 
    generateSocialImage({
      title: title ? title : defaultTitle,
      tagline: metaDescription,
      imagePublicID: 'sprinkle_jj3s59'
    }), [])

  return (
    <Helmet title={title}>
      {/* google */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={socialCard} />

      {/* facebook */}
      <meta type="og:title" content={title} />
      <meta type="og:description" content={metaDescription} />
      <meta type="og:image" content={socialCard} />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={socialCard} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  bodyAttributes: PropTypes.object,
}

export default SEO
