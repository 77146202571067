/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"

const DarkModeToggle = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const toggleColorMode = e => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  const label = isDark ? `Activate light mode` : `Activate dark mode`

  const starStyles = {
    position: "absolute",
    top: "25%",
    left: "5%",
    display: "block",
    width: "0px",
    height: "0px",
    borderRight: "7px solid rgba(0, 0, 0, 0)",
    borderBottom: `5px solid rgba(240, 245, 250, 1)`,
    borderLeft: "7px solid rgba(0, 0, 0, 0)",
    transform: "scale(0.55) rotate(35deg)",
    opacity: isDark ? "1" : "0",
    transition: isDark ? "all 0.2s ease-in-out 0.35s" : "all 0.2s ease-in-out",

    "&:before": {
      borderBottom: `5px solid rgba(240, 245, 250, 1)`,
      borderLeft: "3px solid rgba(0, 0, 0, 0)",
      borderRight: "3px solid rgba(0, 0, 0, 0)",
      position: "absolute",
      height: "0",
      width: "0",
      top: "-3px",
      left: "-5px",
      display: "block",
      content: '""',
      opacity: isDark ? "1" : "0",
      transform: "rotate(-35deg)",
    },

    "&:after": {
      position: "absolute",
      display: "block",
      top: "0px",
      left: "-7px",
      width: "0px",
      height: "0px",
      borderRight: "7px solid rgba(0, 0, 0, 0)",
      borderBottom: `5px solid rgba(240, 245, 250, 1)`,
      borderLeft: "7px solid rgba(0, 0, 0, 0)",
      transform: "rotate(-70deg)",
      content: '""',
      opacity: isDark ? "1" : "0",
    },
  }

  return (
    <button
      onClick={toggleColorMode}
      aria-label={label}
      title={label}
      sx={{
        width: "46px",
        height: "46px",
        boxSizing: "border-box",
        padding: "12px",
        background: "none",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        left: ["-10px", "-10px", "0", "0"],
        right: ["0", "0", "-10px", "-10px"], // move to right to line up with right edge of content
        cursor: "pointer",
        zIndex: "20",
      }}
    >
      {/* sun */}
      <div
        sx={{
          width: "18px",
          height: "18px",
          position: "absolute",
          top: "50%",
          left: "50%",
          opacity: isDark ? "0" : "1",
          transformOrigin: "center",
          transform: isDark
            ? "translate(-50%, -50%) scale(0.6) rotate(0deg)"
            : "translate(-50%, -50%) scale(1) rotate(180deg)",
          transition:
            "transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s",
          background: theme =>
            `radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, ${theme.colors.text} 50%)`,

          "&:before": {
            content: "''",
            position: "absolute",
            display: "block",
            width: "100%",
            height: "100%",
            background: theme =>
              `radial-gradient(circle, ${theme.colors.text} 35%, rgba(0, 0, 0, 0) 36%, rgba(0, 0, 0, 0) 50%, ${theme.colors.text} 50%)`,
            transform: "rotate(45deg)",
          },
        }}
      ></div>

      {/* moon */}
      <div
        sx={{
          width: "20px",
          height: "20px",
          position: "absolute",
          left: "12.5%",
          top: "18.75%",
          background: "rgba(0, 0, 0, 0)",
          borderRadius: "50%",
          boxShadow: theme => `9px 3px 0px 0px ${theme.colors.text}`,
          opacity: isDark ? "1" : "0",
          transform: isDark
            ? "scale(1) rotate(0deg)"
            : "scale(0.3) rotate(65deg)",
          transition:
            "transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s",
        }}
      >
        {/* bigger star */}
        <div sx={starStyles}></div>

        {/* smaller star */}
        <div
          sx={{
            ...starStyles,
            transform: isDark
              ? "scale(0.45) rotate(35deg)"
              : "scale(0.35) rotate(35deg)",
            position: "relative",
            top: "50%",
            left: "37.5%",
            opacity: isDark ? ".8" : "0",
            transition: "all 0.2s ease-in 0.4s",
          }}
        ></div>
      </div>
    </button>
  )
}

export default DarkModeToggle
