// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-docs-page-layout-js": () => import("./../../../src/templates/docs-page-layout.js" /* webpackChunkName: "component---src-templates-docs-page-layout-js" */),
  "component---src-templates-portfolio-list-template-js": () => import("./../../../src/templates/portfolio-list-template.js" /* webpackChunkName: "component---src-templates-portfolio-list-template-js" */),
  "component---src-templates-post-list-template-js": () => import("./../../../src/templates/post-list-template.js" /* webpackChunkName: "component---src-templates-post-list-template-js" */)
}

