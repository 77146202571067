/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { ClipboardContext } from "../utils"
import SEO from "./seo"
import Header from "./header"
import Banner from "./banner"
import BannerChildren from "./banner-children"
import ScrollGradient from "./scroll-gradient"
import Footer from "./footer"

import "./layout.css"
import { useCopyToClipboard } from "react-use"

const variant = {
  initial: {
    opacity: 0,
    x: -200,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 200,
  },
}

const Layout = ({
  children,
  location,
  pageContext: { title = "Home", description },
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [clipboard, copyToClipboard] = useCopyToClipboard()

  return (
    <ClipboardContext.Provider value={{ clipboard, copyToClipboard }}>
      <SEO title={title} description={description} />
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        location={location}
      />
      {location && location.pathname !== "/" ? <ScrollGradient /> : null}
      <Banner location={location}>
        <BannerChildren children={children} />
      </Banner>
      <AnimatePresence exitBeforeEnter>
        <motion.main
          key={location && location.pathname ? location.pathname : "error"}
          variants={variant}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.25,
          }}
          sx={{
            width: "100%",
          }}
        >
          {children}
        </motion.main>
      </AnimatePresence>

      <Footer />
    </ClipboardContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  main: PropTypes.object,
  showBg: PropTypes.bool,
  seoTitle: PropTypes.string,
}

export default Layout
