/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import theme from "../gatsby-plugin-theme-ui/index"

const pathStyles = {
  strokeDasharray: "450",
  strokeDashoffset: "450",
  strokeLinecap: "round",

  "@keyframes draw": {
    to: {
      strokeDashoffset: "0",
    },
  },
}

const AnimatedInitials = ({ styles }) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  return (
    <svg
      width="214"
      height="144"
      viewBox="0 0 214 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: "auto",
        height: "2rem",
        ...styles,
      }}
    >
      <path
        className="g"
        d="M127 18.5C126.414 15.1805 126.813 13.1045 125.5 10C124.403 7.40633 123.764 5.67421 121.5 4C119.809 2.74972 118.602 2.07325 116.5 2C113.706 1.90263 109 5 108 6C107 7 103 12 102 13.5C101 15 98.3653 20.0742 96.5 24.5C94.6533 28.8817 93.8801 31.4497 92.5 36C91.1529 40.4415 90.3624 42.9395 89.5 47.5C88.4423 53.0937 87.7456 56.3125 87.5 62C87.3231 66.0967 87.5 72.5 87.5 72.5C87.5 72.5 88 80 89.5 83.5C91 87 91.4462 87.6336 93.5 89.5C95.5841 91.3939 97.4049 92.4063 100 93.5C102.576 94.5858 104.218 94.7197 107 95C109.526 95.2545 111.477 95.2835 114 95C116.589 94.709 118.561 93.9154 121 93C123.948 91.8936 124.801 91.1211 127.5 89.5C132.096 86.7393 134.315 84.852 138.5 81.5C141.842 78.8227 143.61 77.1599 146.5 74C149.505 70.7145 151.338 68.5692 154 65C155.588 62.8711 156.087 61.7485 157.5 59.5C160.244 55.1357 160.5 53.8617 160.5 53.8617L163 46.5C163 46.5 164.128 41.7574 163.5 39.5C162.818 37.0472 161.678 34.6926 160 33C158.017 31.0002 156.174 29.8827 153.5 29C151.5 28.3398 148.18 28.5475 145 29.5C142.145 30.3553 140.859 31.1781 138.5 33C135.659 35.1943 133.62 37.1032 131.5 40C129.539 42.6786 128.328 44.4577 127 47.5C125.839 50.1604 125.398 51.6247 125 54.5C124.732 56.4342 125.5 59.5 125.5 59.5C125.5 59.5 126.555 62.3579 128 63.5C129.309 64.5343 130.363 64.679 132 65C133.916 65.3757 135.055 65.1692 137 65C139.785 64.7578 141.349 64.3882 144 63.5C147.312 62.3903 148.96 61.2201 152 59.5C157.374 56.4593 160.019 54.1488 165 50.5C170.388 46.5532 173.229 44.102 178.5 40C191.785 29.6608 199.23 23.8586 212.5 13.5"
        stroke={isDark ? theme.colors.modes.dark.text : theme.colors.text}
        strokeWidth="6"
        sx={{ ...pathStyles, animation: "draw .6s ease-in 2s forwards" }}
      />
      <path
        className="j"
        d="M74.5012 11C74.5012 11 75.6487 22.8704 76.0012 30.5C76.5329 42.0082 76.0012 48.4795 76.0012 60C76.0012 74.6447 76 83.1369 76 97.7815C76 102.663 76.2915 105.127 76.0012 110C75.7089 114.908 75.5154 117.689 74.5012 122.5C73.3166 128.119 72.0012 133 70.5012 136.5C69.455 138.941 68.651 141.82 66.0012 142C63.894 142.143 62.9947 141.493 61.5012 140C56.5012 135 54.6187 129.059 50.5012 122.5C47.4745 117.678 46.2403 114.682 43.0012 110C40.6236 106.564 39.0892 104.781 36.5012 101.5C34.0083 98.3394 33.05 96.1285 30.0012 93.5C27.8683 91.6612 26.4591 90.8746 24.0012 89.5C21.5612 88.1354 20.1275 87.4586 17.5012 86.5C14.6537 85.4606 11.0012 84.5 10.0012 84.5C9.00123 84.5 5.25993 84.5099 3.50123 86.5C3.50123 86.5 2.0315 88.1905 2.00123 89.5C1.95325 91.5751 3.31999 92.7827 5.00122 94C6.55889 95.1278 10.0012 96 10.0012 96C10.0012 96 12.997 96.8628 15.0012 97.5C17.6656 98.347 19.2228 98.6891 22.0012 99C25.106 99.3474 26.877 99.5 30.0012 99.5C33.5159 99.5 34.0136 99.5 35.0136 99.5C36.0136 99.5 45.0955 99.1858 49.0012 99C64.4206 98.2665 73.0659 97.7125 88.5012 97.5C97.2872 97.379 102.22 97.8034 111.001 97.5C121.373 97.1417 127.167 96.4588 137.501 95.5C144.93 94.8107 149.092 94.3767 156.501 93.5C162.562 92.7829 165.945 92.2585 172.001 91.5C178.442 90.6934 182.06 90.2979 188.501 89.5C197.683 88.3627 202.828 87.7058 212.001 86.5"
        stroke={isDark ? theme.colors.modes.dark.text : theme.colors.text}
        strokeWidth="6"
        sx={{
          ...pathStyles,
          animation:
            "draw .4s cubic-bezier(0.015, 0.590, 0.920, 0.325) forwards",
          animationDelay: "1.5s",
        }}
      />
    </svg>
  )
}

export default AnimatedInitials
