/** @jsx jsx */
import { jsx } from "theme-ui"

const Emoji = ({ children, emojiName }) => {
  return (
    <span role="img" aria-label={emojiName}>
      {children}
    </span>
  )
}

export default Emoji
